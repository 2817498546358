import React from "react";
import { Routes, Route } from 'react-router-dom';
import Mechanism from "../views/Mechanism";
import Content from "../views/Home/Content";
import Introduction from "../views/Introduction";
import Download from "../views/Download";
const Routers = () => (
    <Routes>
        <Route path="/" element={<Content />} />
        <Route path="/mechanism" element={<Mechanism />} />
        <Route path="/instruction" element={<Introduction />} />
        <Route path="/download" element={<Download />} />
    </Routes>
)
export default Routers;