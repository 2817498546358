import Web3 from "web3";
import OutPutABI from "../../../config/Output.json";
// import { Contract } from "@ethersproject/contracts";
const link =()=>{
    const web3 = new Web3(window.ethereum);
    // console.log(provider, "provider");
    const contractAddress = "0xd0d8e8379B2005F34E49D1B9981659A0c667eB3D";
      
    return new web3.eth.Contract(OutPutABI,contractAddress);
    
}


export  default link