import { Box, styled } from "@mui/material";
export const FooterPage = styled(Box)(({ theme }) => ({
    height: 80,
    marginTop:20,
    background: "#000000",
    display: "flex",
    justifyContent: "space-between",
    padding: "0 15px",
    alignItems: "center",
    span: {
        color: "#FFF",
    },
    ">div": {
        display: "flex",
        '.img': {
            width: 39,
            height: 39
        },
        '.img:nth-of-type(1)': {
            marginRight: 15,
        }
    }
}))