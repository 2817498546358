import React, { useEffect, useState } from "react";
import { OutputContent, OutputPage } from "./style";
import axios from "axios";
import { SliceAddress } from "../../../utils";
import link from "./test";
import { List, message } from "antd";
import copy from "copy-to-clipboard";
import { CopyOutlined } from "@ant-design/icons";
function App({ addr, onDataPass }) {
  // console.log(addr)
  const [U, setU] = useState([]);
  const [valueU, setValueU] = useState([]);
  const [valueT, setValueT] = useState([]);
  const [Time, setTime] = useState([]);
  const [initLoading, setInitLoading] = useState(true);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [list, setList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5; // 每页显示的项目数
  const maxItems = 200; // 最多显示200个条目
  const OutPutContact = link();
  const number = data.indexOf(addr);
  if (number > -1) {
    console.log("表示数组中含有此字符串", number);
  } else {
    console.log("不含有此字符串，a的值为-1");
  }
  // console.log(data, "data");
  const getDetail = async (arr) => {
    // console.log(arr,'arr')
    await OutPutContact.methods
      .getDetail(arr)
      .call()
      .then((res) => {
        // console.log(res, "res");
        setU(res[0]);
        setValueU(res[1]);
        setValueT(res[2]);
        setTime(res[3]);
      });
  };

  const DataHandle = async () => {
    try {
      const res = await axios.get(
        "https://www.oklink.com/api/v5/explorer/address/rich-list",
        {
          params: {
            chainShortName: "BSC",
            address: "0xF08199DD301CF2669aBb46F79881d03B7E7E4F3e",
          },
          headers: {
            "OK-ACCESS-KEY": "6077fbae-cce2-4439-a9be-720e2e6678db",
            // Cookie:
            //   " __cf_bm=HC6C49qcbf2U00WFL1KG5uCIhsOzCqCV.4G3y4aYJ6s-1723103143-1.0.1.1-I0nsVmbV63n9IejHqfv8wcvnEGNxe9CDrCDOUW5FhTcNIcd5JuTCl_rJg2s1NwetiFDVEsy.ljFEeyx5sobYWg",
          },
        }
      );

      // console.log(res,'res')
      const arr = res.data.data.map((obj) => obj.address);
      const limitedArr = arr.slice(0, maxItems); // 只取前200个
      getDetail(limitedArr);

      setData(limitedArr); // 将200个数据保存到状态中
      setList(limitedArr.slice(0, itemsPerPage)); // 初始化第一页数据
    } catch (error) {
      console.error(error);
    } finally {
      setInitLoading(false);
    }
  };
  // console.log(valueU, "valueU");
//   function convertTimestampToDays(timestamp) {
//     // const millisecondsInADay = 86400000; // 一天的毫秒数
//     // const days = Math.floor(timestamp / millisecondsInADay);
//     var today = new Date(timestamp * 1000);
//     //日期
//     const DD = String(today.getDate()).padStart(2, '0'); // 获取日
//     const MM = String(today.getMonth() + 1).padStart(2, '0'); //获取月份，1 月为 0
//     const yyyy = today.getFullYear(); // 获取年
// // 时间
//     const hh =  String(today.getHours()).padStart(2, '0');       //获取当前小时数(0-23)
//     const mm = String(today.getMinutes()).padStart(2, '0');     //获取当前分钟数(0-59)
//     const ss = String(today.getSeconds()).padStart(2, '0');     //获取当前秒数(0-59)
//     const days = yyyy + '-' + MM + '-' + DD + ' ' + hh + ':' + mm + ':' + ss;
//     console.log(days)
//     return days;
//   }
  // const sendDataToParent = () => {
  //   console.log(number, "number");
  //   if (number >= 0) {
  //     console.log(2);
  //     onDataPass([
  //       (
  //         Number(valueU[number]) / Math.pow(10, 18)).toFixed(2)


          
  //         ? (Number(valueU[number]) / Math.pow(10, 18)).toFixed(2)
  //         : 0,
  //       (Number(valueT[number]) / Math.pow(10, 18)).toFixed(2)
  //         ? (Number(valueT[number]) / Math.pow(10, 18)).toFixed(2)
  //         : 0,
  //       convertTimestampToDays(Number(Time[number])),
  //     ]); // 调用父组件传递的回调函数
  //   } else {
  //     console.log(1);
  //     onDataPass([0, 0, 0]);
  //   }
  // };
  // console.log(       convertTimestampToDays(Number(Time[number])), " Number(Time[number])");
  useEffect(() => {
    DataHandle();
  }, []);
  // useEffect(() => {
  //   sendDataToParent();
  // }, [number]);

  const onLoadMore = () => {
    setLoading(true);
    const nextPage = currentPage + 1;
    const startIndex = (nextPage - 1) * itemsPerPage;
    const endIndex = Math.min(startIndex + itemsPerPage, maxItems); // 确保不超过200个

    const newItems = data.slice(startIndex, endIndex);
    setList((prevList) => [...prevList, ...newItems]);
    setCurrentPage(nextPage);
    setLoading(false);
  };

  const loadMore =
    !initLoading &&
    !loading &&
    list.length < data.length &&
    list.length < maxItems ? (
      <div className="more" onClick={onLoadMore}>
        <span>更多</span>
        <div className="img">
          <img src="/images/arrow.webp" alt="" />
        </div>
      </div>
    ) : null;
  const copyHandler = (content) => {
    copy(content);
    message.success("复制成功");
  };

  return (
    <>
      <OutputPage>LP前200名的地址以及产出U量</OutputPage>
      <OutputContent>
        <div className="list">
          <span>LP前200名</span>
          <span>地址</span>
          <span>产出U量</span>
        </div>
        <List
          loading={initLoading}
          loadMore={loadMore}
          dataSource={list}
          renderItem={(item, index) => (
            <div className="list" key={index}>
              <span className="special">{index + 1}</span>
              <span>
                {SliceAddress(item)}
                <CopyOutlined onClick={() => copyHandler(item)} />
              </span>
              <span>
                {U[index]
                  ? (Number(U[index]) / Math.pow(10, 18)).toFixed(2)
                  : 0}
              </span>
            </div>
          )}
        />
      </OutputContent>
    </>
  );
}

export default App;
