import React from "react"
import { Chapter, IntroductionPage, Overview } from "./style"

function App() {
    return (
        <>
            <IntroductionPage>
                <div>
                    <img src="/images/logo.webp" alt="" />
                </div>

                <div style={{ textAlign: "center" }}>1</div>
            </IntroductionPage>
            <Overview>
                <p style={{ color: "#ffc000", textAlign: "center" }}>目录</p>

                <Chapter>
                    <ul className="toc">
                        <li>
                            <span>1.0 项目概述</span>
                            <span className="aa"></span>
                            <span>3</span>
                        </li>
                        <li>
                            <span>1.1 项目背景与愿景</span>
                            <span className="aa"></span>
                            <span>3</span></li>
                        <li>
                            <span>1.2 项目目标与关键优势</span>
                            <span className="aa"></span>
                            <span>4</span>
                        </li>
                        <li>
                            <span>1.3 核心理念与价值观</span>
                            <span className="aa"></span>
                            <span>5</span>
                        </li>
                    </ul>



                    <ul className="toc">
                        <li>
                            <span>2.0 项目结构与生态系统</span>
                            <span className="aa"></span>
                            <span>6</span>
                        </li>
                        <li>
                            <span>2.1 平台主要功能开发</span>
                            <span className="aa"></span>
                            <span>6</span></li>
                        <li>
                            <span>2.2 技术架构与安全性</span>
                            <span className="aa"></span>
                            <span>8</span>
                        </li>
                        <li>
                            <span>2.3 生态系统组成</span>
                            <span className="aa"></span>
                            <span>10</span>
                        </li>
                    </ul>




                    <ul className="toc">
                        <li>
                            <span>3.0 经济模型</span>
                            <span className="aa"></span>
                            <span>9</span>
                        </li>
                        <li>
                            <span>3.1 手续费机制</span>
                            <span className="aa"></span>
                            <span>9</span></li>
                        <li>
                            <span>3.2 收益与分红模式</span>
                            <span className="aa"></span>
                            <span>11</span>
                        </li>
                        <li>
                            <span>3.3 BBC 销毁机制</span>
                            <span className="aa"></span>
                            <span>12</span>
                        </li>
                        <li>
                            <span>3.4 激励与奖励体系</span>
                            <span className="aa"></span>
                            <span>13</span>
                        </li>
                    </ul>



                </Chapter>
                <div style={{ fontSize: 12 }}>2/21</div>

            </Overview>
            <Overview>
                <Chapter>
                    <ul className="toc">
                        <li>
                            <span>4.0 生态发展规划</span>
                            <span className="aa"></span>
                            <span>18</span>
                        </li>
                        <li>
                            <span>4.1 第一阶段：基础功能与初期生态</span>
                            <span className="aa"></span>
                            <span>18</span></li>
                        <li>
                            <span>4.2 第二阶段：扩展与深度合作</span>
                            <span className="aa"></span>
                            <span>20</span>
                        </li>

                    </ul>
                </Chapter>
                <p>1.0 项目概述</p>
                <div className="title">1.1 项目背景与愿景</div>
                <div className="content">在全球区块链技术和Web3应用快速发展的背景下，数字经济正逐步渗透到日常生活的各个方面。BBC旨在构建一个集成多个功能模块的开放平台，涵盖区块链钱包、短视频、游戏、电商等多种服务，为用户提供全方位数字体验。愿景是创建一个去中心化、透明且具有高度信任度的生态系统，促进用户、开发者和服务提供商之间的合作与创新。通过开放平台的模式，我们希望打破传统互联网的壁垒，让每个人都能轻松参与并受益于数字经济的发展。
                </div>

                <div style={{ fontSize: 12 }}>3/21</div>

            </Overview>
            <Overview>
                <div className="title">1.2 项目目标与关键优势</div>
                <div style={{ display: "flex" }}>

                    <div style={{ width: "50%" }}>
                        <span>项目目标：</span>
                        <ul>
                            <li>创建多功能一体化平台：提供包括区块链钱包、短视频、本地生活服务、电商和游戏等多种功能，为用户提供便捷的一站式服务。</li>


                        </ul>
                    </div>
                    <div style={{ width: "50%" }}>
                        <img style={{ width: "100%", height: "200px" }} src="/images/key.webp" alt="" />
                    </div>
                </div>
                <ul>
                    <li>促进BBC的销毁与价值提升：通过平台内的各种交易和活动，增加BBC的使用频率和销毁量，提升其市场价值。</li>
                    <li>鼓励社区参与与共建：支持开发者和用户自主创建和分享内容，通过合理的收益分配机制，激励更多人参与平台的建设和发展。</li>
                </ul>


                <span>关键优势：</span>
                <ul>
                    <li>去中心化和开源性：平台完全开源，所有代码透明可查，无后门，确保用户数据的安全与隐私。</li>
                    <li>灵活的经济模型：通过平台内的手续费机制和收益分配策略，保障开发者和用户的利益，促进生态系统的良性循环。</li>
                </ul>
                <div style={{ fontSize: 12 }}>4/21</div>
            </Overview>
            <Overview>
                <ul>
                    <li>多样化的应用场景：集成多个热门应用领域，如区块链钱包、短视频、电商和游戏等，为用户提供丰富的体验和参与机会。</li>
                    <li>全球化的用户基础：平台面向全球用户，具备强大的扩展潜力和市场影响力。</li>

                </ul>
                <div className="title">1.3 核心理念与价值观</div>
                <div className="content">BBC的核心理念是“开放、共赢、创新”。我们相信，开放的技术和透明的规则是构建信任的基础，共赢的生态环境能够激励更多的创造力和创新。致力于为用户提供一个公平、公正、透明的数字生态系统，使每个参与者都能分享数字经济的红利。</div>
                <span>核心价值观：
                </span>
                <ol>
                    <li>1) 开放透明：坚持开源和去中心化，确保所有参与者的平等权利和信息透明。</li>
                    <li>2) 用户至上：以用户体验为核心，提供安全、便捷、高效的服务。</li>
                    <li>3) 创新驱动：鼓励创新，持续改进，推动数字技术的发展和应用。</li>
                    <li>4) 共赢合作：尊重和保护开发者和用户的利益，打造一个共赢的生态系统。</li>
                </ol>
                <div style={{ fontSize: 12 }}>5/21</div>
            </Overview>
            <Overview>
                <p style={{ color: "#ffc000" }}>2.0 项目结构与生态系统</p>
                <div className="title">2.1 平台主要功能开发</div>
                <div style={{ display: "flex", alignItems: "center" }}>

                    <div style={{ width: "50%" }}>
                        <span>Ａ）区块链钱包</span>
                        <div className="content">区块链钱包是平台的核心模块之一，支持多种主流加密货币的存储、转账和管理功能。用户可以通过钱包进行安全的数字资产交易，包括购买、出售和兑换。钱包具备内置加密安全措施，多重签名和二次验证，以确保用户资产安全。</div>
                    </div>
                    <div style={{ width: "50%", height: 100 }}>
                        <img src="/images/dun.jpg" alt="" />
                    </div>
                </div>

                <span>Ｂ）网页搜索与收藏</span>
                <div className="content">平台提供集成的网页搜索与收藏功能，用户可以在平台内快速查找所需的信息，并将有价值的网页或资源添加到个人收藏夹中。该功能支持智能推荐和个性化设置，帮助用户更有效地管理和访问网络资源。</div>
                <div style={{ fontSize: 12 }}>6/21</div>
            </Overview>
            <Overview>
                <span>Ｃ）游戏功能</span>
                <div className="content">BBC的游戏功能模块为用户提供了丰富多样的游戏体验，涵盖了竞技、冒险、策略等多种类型。平台支持传统的游戏玩法，引入了区块链技术，使玩家可以通过游戏获取和交易数字资产。同时，开发者可以在平台上发布和运营游戏，平台提供收益分成机制，激励优质内容的持续产出。</div>
                <span>Ｄ）短视频功能</span>
                <div className="content">短视频功能为用户提供了一个创作和分享短视频的平台。用户可以上传和浏览各种题材的短视频内容，平台支持视频打赏和互动功能，增强用户参与感。对于内容创作者，平台提供收益分成机制，通过广告收入和用户打赏获取收益。</div>
                <span>Ｅ）本地生活服务</span>
                <div className="content">本地生活服务模块涵盖了餐饮、娱乐、购物等多种生活服务。用户可以通过平台查找和预订本地服务商的产品和服务，同时享受平台提供的优惠和积分奖励。该模块支持线上支付和订单管理，方便用户的日常生活。</div>
                <span>F）电商功能</span>
                <div className="content">电商功能使平台成为一个多功能购物中心，用户可以</div>
                <div style={{ fontSize: 12, marginTop: 20 }}>7/21</div>

            </Overview>
            <Overview>
                <div className="content">购买各类商品，包括实物商品和虚拟商品。平台支持商家入驻，提供一站式商品管理、支付和物流服务。用户可以通过区块链钱包进行支付，享受安全、快捷的购物体验。</div>
                <div><img src="/images/keyBoard.jpg" alt="" /></div>
                <div className="title">2.2 技术架构与安全性</div>
                <span>A）开源与无后门政策</span>
                <div style={{ fontSize: 12, marginTop: 40 }}>8/21</div>

            </Overview>
            <Overview>
                <div className="content">BBC的平台代码完全开源，所有核心模块的源代码公开透明，允许任何人审查和验证。这一政策确保系统的透明性和信任度，避免未经授权的更改或隐藏功能的可能性。平台严格遵循无后门政策，保证所有的代码和功能均在开源社区</div>
                <div><img src="/images/pointer.jpg" alt="" /></div>
                <div className="content">的监督下运行，杜绝任何可能损害用户数据和资产的隐蔽途径。</div>
                <span>B）数据隐私与安全措施</span>
                <div className="content">数据隐私与安全是BBC的重中之重。平台采用多层次的安全措施来保护用户数
                    据，包括但不限于：</div>
                <ul>
                    <li>加密传输：所有数据在传输过程中均采用SSL/TLS协议加密，确保数据在网络传输中的安全性。</li>
                    <li>去中心化存储：敏感数据和关键信息使用分布式存</li>
                </ul>
                <div style={{ fontSize: 12, marginTop: 40 }}>9/21</div>

            </Overview>
            <Overview>
                <div className="content">止单点故障和数据篡改。</div>
                <ul>
                    <li>权限控制：严格的权限管理机制，确保只有授权人员能够访问和操作关键数
                        据和系统功能。</li>
                    <li>多因素认证：为用户账户提供多因素身份验证选项，增强账户安全性。</li>
                    <li>安全审计：定期进行内部和第三方安全审计，及时发现和修复潜在的安全漏
                        洞。</li>
                </ul>
                <div className="title">2.3 生态系统组成</div>
                <div style={{ display: "flex" }}>
                    <div style={{ width: "50%" }}>
                        <span>A）BBC添加LP核心成员</span>
                        <div className="content">BBC添加LP核心成员是BBC生态系统的战略中枢，他们由资深投资者和技术专家组</div>
                    </div>

                    <div style={{ width: "50%", height: 100 }}>
                        <img src="/images/dianNao.jpg" alt="" />
                    </div>
                </div>
                <div className="content">成，负责制定和引导平台的长期发展战略。成员对平台的技术和经济模型有深入的理解，具备丰富的市场运作经验。他们的主要职责包括：评估和优化BBC的销毁机制、制定收益分配策略、监督平台治理结构的执行，以确保平台的健康和可持续发展。</div>
                <div style={{ fontSize: 12, marginTop: 40 }}>10/21</div>

            </Overview>
            <Overview>
                <span>B）开发者与技术团队</span>
                <div className="content">开发者和技术团队是平台创新和功能扩展的主要驱动力。他们负责设计、开发和维护平台的各个功能模块，包括区块链钱包、游戏、短视频、电商等。为了确保平台的稳定性和安全性，技术团队严格遵循开源和无后门的开发原则。此外，平台提供完善的开发工具和API，鼓励第三方开发者在平台上进行创新，发布新应用和服务，丰富平台生态。</div>
                <span>C）玩家与用户社区</span>
                <div className="content">玩家与用户社区是BBC生态系统的核心用户群体，他们的活跃参与为平台注入了生机和动力。社区成员是平台服务的主要消费者，也是内容和服务的创造者。通过参与游戏、上传短视频、进行本地生活服务消费等活动，用户社区为平台带来了流量和使用场景。平台设置多种互动和反馈渠道，社区成员可以直接参与平台治理和决策，提供意见和建议，从而增强社区的凝聚力和参与感。</div>
                <div style={{ fontSize: 12, marginTop: 60 }}>11/21</div>
            </Overview>
            <Overview>
                <p style={{ color: "#ffc000" }}>3.0 经济模型</p>
                <div className="title">3.1 手续费机制</div>
                <span>A）平台手续费收取与分配机制</span>
                <div className="cconten">BBC平台在各种交易和服务中收取固定比例的手续费，标准费率为5%。该手续费的主要来源包括区块链钱包交易、短视频打赏、电商购物等。平台将收取的手续费按以下比例进行分配：</div>
                <ul>
                    <li>60% 分配给开发者或内容创作者，以激励优质内容和服务的提供。</li>
                    <li>10% 分配给达到BBC添加LP标准的用户，作为对他们参与平台治理和支
                        持的回报。</li>
                    <li>10% 用于平台日常运营广，包括技术维护、用户支持和活动宣传等。</li>
                    <li>10% 用于奖励提供计算资源的服务器节点运营者，支持平台的分布式架构
                        和稳定运行。</li>
                    <li>10% 作为团队成员的奖励基金，根据贡献和成果进行分配，以激励团队持
                        续</li>
                </ul>
                <div style={{ fontSize: 12, marginTop: 60 }}>12/21</div>
            </Overview>
            <Overview>
                <div>
                    <img src="/images/logo.webp" alt="" />
                </div>

                <div style={{ fontSize: 12, marginTop: 80 }}>13/21</div>
            </Overview>
            <Overview>
                <span>B）游戏充值手续费</span>
                <div className="content">在游戏模块中，用户进行虚拟商品购买或游戏内充值时，平台同样收取5%的手
                    续费。具体分配如下：</div>
                <ul>
                    <li> 60% 归属于游戏开发者，作为他们开发和维护游戏的收入来源。</li>
                    <li>10% 分配给达到BBC添加LP标准的用户，激励其参与平台治理和生态建设。</li>
                    <li>10% 用于平台的日常运营和游戏推广活动，支持游戏生态的健康发展。</li>
                    <li>10% 作为服务器资源提供者的奖励，确保游戏平稳运行和用户体验优化。</li>
                    <li>
                        10% 用于团队成员的奖励分配，支持游戏开发团队的持续创新和发展。
                    </li>
                </ul>
                <div style={{ fontSize: 12, marginTop: 40 }}>14/21</div>

            </Overview>
            <Overview>
                <div className="title">3.2 收益与分红模式</div>
                <span>A）开发者收益分配</span>
                <div className="content">BBC通过平台手续费的收益，设立专门的开发者收益分配机制。对于开发者和内容创作者，无论是游戏、短视频、电商还是其他应用模块，其所创造的内容或服务每产生一笔交易，都将获得平台手续费的60%作为收入。这种机制一方面为开发者提供了稳定的收入来源，也激励他们不断创新和提升内容质量，进一步丰富平台生态。</div>
                <span>Ｂ）BBC添加LP玩家分红</span>
                <div className="content">BBC添加LP机制是BBC蚂蚁中一项独特的激励措施。持有并锁定一定数量BBC的用户（即BBC添加LP玩家）能在平台治理中享有投票权，同时可以从平台手续费中获取分红。玩家每月自动获得10%的手续费收益作为分红。分红机制旨在奖励忠实用户，增强他们对平台发展的信心和支持，促进社区长期稳定。</div>
                <span>Ｃ）服务器搭建奖励</span>
                <div className="content">为了支持平台的去中心化架构和稳定运行，BBC为提供计算资源的服务器搭建</div>
                <div style={{ fontSize: 12, marginTop: 40 }}>15/21</div>
            </Overview>
            <Overview>

                <div className="content">者提供奖励。服务器节点在平台运作中承担着关键的计算和数据存储功能。平台将从所有手续费中提取10%作为服务器搭建奖励，分配给符合条件的节点运营者。这些奖励可以补偿运营者的硬件和维护成本，同时激励更多人参与到平台的基础设施建设中来，确保平台的可靠性和扩展性。</div>
                <div className="title">3.3 BBC 销毁机制</div>
                <div style={{ display: "flex" }}>

                    <div style={{ width: "50%" }}>
                        <span>A）交易手续费销毁</span>
                        <div className="content">平台从各种交易中收取的5%手续费中的部分BBC，将定期被永久销毁。具体销毁比例由社区投票决定，以</div>
                    </div>
                    <div style={{ width: "50%", height: 100 }}>
                        <img src="/images/luoSi.jpg" alt="" />
                    </div>
                </div>
                <div className="content">确保机制的透明性和参与者的共识。这一过程通过智能合约自动执行，确保操作的不可逆性和公开透明。</div>


                <span>B）活动和激励销毁</span>
                <div className="content">平台会不定期举办各类活动，如游戏竞赛、社区贡献奖励等，部分奖励以BBC形式发放后将被销毁。全面提升活动吸引力，有效减少市场上BBC流通量。</div>
                <div style={{ fontSize: 12, marginTop: 40 }}>16/21</div>
            </Overview>
            <Overview>
                <span>C）公益捐赠销毁</span>
                <div className="conten">在支持公益项目时，平台计划会使用部分BBC进行捐赠，并相应地销毁这些
                    BBC。此举在支持慈善事业的同时通过销毁增加BBC的市场稀缺性。</div>
                <div className="title">3.4 激励与奖励体系</div>
                <span>A）玩家奖励模式</span>
                <ul>
                    <li>游戏内奖励：玩家在平台上的游戏中可以通过完成任务、参与竞技或击败BOSS等方式获得BBC或其他虚拟奖励。奖励可以用于交易、购买虚拟物品或参与其他平台活动。</li>
                    <li>参与活动奖励：平台定期举办各类活动，包括短视频创作比赛、社区互动问答等。玩家参与这些活动，可以获得BBC奖励，有机会赢得特殊道具和独家内容的访问权。</li>
                    <li>贡献度奖励：根据玩家在社区中的活跃度和贡献度（如内容创作、帮助新用户等），平台将定期发放BBC奖励，表彰他们对平台生态的贡献。</li>
                </ul>
                <span>B）开发者激励措施</span>
                <div style={{ fontSize: 12, marginTop: 40 }}>17/21</div>
            </Overview>
            <Overview>
                <ul>
                    <li>收入分成：开发者在平台上发布的应用、游戏或内容，通过用户付费或广告收入获取收益，平台将根据规定比例分成，确保开发者获得合理的经济回报。</li>
                    <li>开发者基金：平台设立了专项开发者基金，用于支持有潜力的开发项目和团队。这些资金可用于开发初期的资金支持、技术协助和市场推广等。</li>
                    <li>技术支持和资源共享：平台为开发者提供全面的技术支持，包括API接口、开发工具包和技术文档。同时，开发者还可以享受平台的资源共享，如数据分析、用户行为洞察等，帮助优化产品设计和用户体验。</li>
                    <li>推广和宣传：优秀的开发者作品将获得平台的重点推广资源，包括首页推荐、社交媒体宣传等，帮助提升作品的曝光度和用户量。</li>
                </ul>
                <div style={{ fontSize: 12, marginTop: 40 }}>18/21</div>

            </Overview>
            <Overview>
                <p style={{ color: "#ffc000" }}>4.0 生态发展规划</p>
                <div className="title">4.1 第一阶段：基础功能与初期生态</div>
                <span>A）核心成员组建与职责</span>
                <div className="content">在BBC的初期建设中，核心成员的组建是关键一步。这些成员包括资深区块链
                    专家、产品经理、市场营销专家以及社区管理者。</div>
                <ul>
                    <li>战略规划：制定平台的发展路线图和业务策略，确定平台的功能优先级和发展方向。</li>
                    <li>技术研发：负责核心技术架构的搭建，包括区块链基础设施、安全系统和平台的各个功能模块。</li>
                    <li>市场拓展：策划并实施市场推广策略，吸引早期用户和合作伙伴，扩大平台的影响力。</li>
                    <li>社区建设：建立和维护用户社区，推动社区参与平台治理和决策，增强用户黏性。</li>
                    <li>项目管理：协调各团队的工作进度和资源分配，确保平台按计划上线并稳定运营。</li>
                </ul>
                <span>B）主要功能上线与推广</span>
                <span>在第一阶段，BBC将重点推出以下核心功能模块：</span>
                <div className="content">1) 区块链钱包：提供安全的数字资产管理功能，支持多种主流加密货币的存储
                    和交易。</div>
                <div className="content">2) 短视频功能：上线短视频创作和分享平台，用户可以上传、浏览和打赏视频
                    内容，鼓励社区互动。</div>
                <div className="content">3) 游戏模块：推出首批区块链游戏，玩家可通过游戏内活动获取虚拟资产，并
                    体验区块链的独特玩法。</div>
                <div className="content">4) 电商功能：上线基础的电商服务，用户可以使用加密货币进行购物和交易。</div>
                <div style={{ fontSize: 12, marginTop: 40 }}>19/21</div>
            </Overview>
            <Overview>
                <div className="title">4.2 第二阶段：扩展与深度合作
                </div>
                <span>A）Web3项目拓展</span>
                <div>
                    在第二阶段，BBC将重点拓展Web3项目，进一步丰富平台生态系统。
                </div>
                <ul>
                    <li>去中心化应用（DApps）扩展：支持更多类DApps，包括去中心化金融（DeFi）、NFT市场、DAO治理工具等，增强平台的多样性和用户互动。</li>
                    <li>智能合约开发：提供智能合约开发工具和模板，降低开发者的技术门槛，鼓励他们创建新的应用场景和业务模式。</li>
                    <li>跨链互操作性：引入跨链技术，实现与其他区块链网络的互操作，支持更多资产和应用的无缝集成，提升平台的开放性和兼容性。</li>
                </ul>
                <span>B）广告宣发与市场推广</span>
                <ul>
                    <li>精准广告投放：利用平台用户数据，开展精准的广告投放策略，为广告主提供高效的宣传服务，同时为平台创造新的收入来源。</li>
                    <li>品牌合作：与知名品牌合作进行联合营销活动，提升平台的公众认知度和美誉度。</li>
                    <li>国际化推广：制定全球市场推广计划，针对不同区</li>
                </ul>
                <div style={{ fontSize: 12, marginTop: 40 }}>20/21</div>
            </Overview>
            <Overview>
                <span>化的活动和内容，吸引全球用户。</span>
                <div>C）技术支持与合作伙伴关系</div>
                <ul>
                    <li>技术支持：提供全天候的技术支持服务，帮助开发者解决技术问题，确保平台的稳定性和安全性。</li>
                    <li>生态合作伙伴：扩大与技术提供商、区块链项目、金融机构等的合作，建立一个强大的生态合作网络，共同推动平台的发展。</li>
                </ul>
                <div style={{ fontSize: 12, marginTop: 80 }}>21/21</div>
            </Overview>

        </>
    )
}
export default App