import React from "react"
import { Care, MechanismPage, Title } from "./style"

function App() {
    return (
        <>
            <Title>总量2.8亿 / 矿池2.5亿</Title>
            <MechanismPage>
                <div>添加LP挖矿Usdt(上)➕BBC(下)自动转到合约锁48小时，到时间后不动可持续产出</div>
                <div>
                48小时后转账0BNB到合约地址给自己解锁LP(等于撤LP)同时停止产出
                </div>
                <div>可复投后根据最后一次复投时间到期转账一次全部解锁并且移除LP</div>
                <div>添加LP大于双边总和100USDT每秒自动挖矿➕1%分红USDT➕1%自动销毁</div>
                <div>
                    <p>每天产出5万枚</p>
                    <p>第一期60天减产每天产出2.5万枚</p>
                    <p>第二期60天减产每天产出12500枚</p>
                    <p>第三期60天减产每天产出6250枚后恒定，税永久0.25%分红USDT➕0.25%销毁</p>
                </div>
            </MechanismPage>
            <Care>⚠️温馨提示:由于添加LP会自动转合约地址锁仓2天，所以AVE会显示:添加LP，移除LP，添加LP，三次记录</Care>
        </>
    )
}
export default App