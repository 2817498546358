import { Box, styled } from "@mui/material";
export const OutputPage = styled(Box)(({ theme }) => ({
    padding: "0 15px",
    fontWeight: "700",
    fontSize: 14,
    lineHeight: "42px"
}))

export const OutputContent = styled(Box)(({ theme }) => ({
    padding: "5px 15px 20px",
    fontSize: 14,
    background: "#FFFFFF",
    ".list": {
        display: "flex",
        borderBottom: "1px solid  #999999",
        justifyContent: "space-between",
        lineHeight: "36px",
        ".special": {
            paddingLeft: 20,
        },
    },
    ".more": {
        justifyContent: "center",
        lineHeight: "36px",
        alignItems:"Center",
        display: "flex",
        ".img": {
            width: 15,
            height: 9,
            marginLeft: 7
        }
    }
}))
