import React from "react"
import Header from "./Header"
import { HomePage } from "./style"
import Footer from "./Footer"
import Routers from "../../route"


function App() {
    return (
        <>
            <HomePage>
                <div>
                    <Header></Header>
                    <div style={{marginTop:60}}>
                    <Routers />
                    </div>
                </div>
                <Footer></Footer>
            </HomePage>
        </>
    )
}

export default App