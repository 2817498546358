import React, { useState, useEffect } from "react";
import { Top } from "./style";
import { linkWallet } from "../../../utils/metamask";
import { SliceAddress } from "../../../utils";
import { useNavigate, useLocation } from "react-router-dom";
function App() {
  const [wallet, setWallet] = useState("连接钱包");
  const LineClick = async () => {
    if (typeof window.ethereum === "undefined") {
      console.log("Please install MetaMask.");
    } else {
      try {
        const account = await linkWallet();
        setWallet(account);
      } catch (error) {
        console.log(error, "error");
      }
    }
  };
  if (window.ethereum) {
    LineClick();
  }
  const navigate = useNavigate();
  const [select, setSelect] = useState("");
  const location = useLocation();

  useEffect(() => {
    const currentTab = tabs.find((tab) => tab.path === location.pathname);
    if (currentTab) {
      setSelect(currentTab.value);
    }
  }, [location.pathname]);
  const tabs = [
    {
      value: "mechanism",
      label: "机制",
      path: "/mechanism",
    },
    {
      value: "instruction",
      label: "生态简介",
      path: "/instruction",
    },
    {
      value: "download",
      label: "Web3下载",
      path: "/download",
    },
  ];
  const handleTabClick = (tab) => {
    setSelect(tab.value);
    navigate(tab.path);
  };
  return (
    <>
      <Top>
        <div className="left">
          <div className="logo" onClick={() => navigate("/")}>
            <img src="/images/logo.webp" alt="logo" />
          </div>
          <ul>
            {tabs.map((tab, index) => (
              <li
                key={index}
                className={tab.value === select ? "tab" : ""}
                onClick={() => handleTabClick(tab)}
              >
                {tab.label}
              </li>
            ))}
          </ul>
        </div>
        <div className="right" onClick={LineClick}>
          {wallet === "连接钱包" ? "连接钱包" : SliceAddress(wallet)}
        </div>
      </Top>
    </>
  );
}

export default App;
