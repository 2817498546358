import { Box, styled } from "@mui/material";
export const Search = styled(Box)(({ theme }) => ({
    background: "#ff9e29",
    padding: "2px 15px",
    height: 62,
    "input": {
        borderRadius: 23,
        border: "1px solid #000",
        background: "#ff9e29",
        fontSize: 15,
        color: "#4D4D4D",
        lineHeight: "46px",
        width: "100%",
        padding: "0 18px",
    }
}))
export const Top = styled(Box)(({ theme }) => ({
    background: "#ff9e29",
    position:"fixed",
    width:"100vw",
    zIndex:1,
    top:0,
    left:0,
    padding: "0 15px",
    height: 60,
    display: "flex",
    alignItems: "Center",
    fontFamily: " Microsoft YaHei",
    justifyContent: "space-between",
    ".logo": {
        width: 47,
        height: 47,
    },
    ".left": {
        display: "flex",
        ul: {
            fontWeight: "bold",
            alignItems: "Center",
            display: "flex",
            li: {
                cursor: "Pointer",
                margin: "0 3px",
                lineHeight: "32px"
            }
        }
    },
    ".right": {
        fontSize: 15,
        color: "#FFFFFF",
        background: " #000000",
        borderRadius: 8,
        width: 95,
        lineHeight: "35px",
        textAlign: "center",
        cursor: "pointer"
    },
    '.tab': {
        borderBottom: "2px solid",
        lineHeight: "30px !important"
    }
}))