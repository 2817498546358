import { Box, styled } from "@mui/material";
export const DownPage = styled(Box)(({ theme }) => ({
    padding: "0 15px",
    background: "#FFFFFF",
    marginBottom: -20,
    ul: {
        display: "flex",
        padding: "14px 0 30px",
        li: {
            width: "25%",
            textAlign: "center",
            // display: "flex",
            p: {
                fontSize: 10,

            },
            "div:nth-of-type(1)": {
                fontWeight: 700,
                margin: "8px auto",
                fontSize: 18,

            },
            "div:nth-of-type(2)": {
                fontSize: 11,

            },
        },
        ".person": {
            height: 24,
            width: 24,
        },
        ".star": {
            justifyContent: "space-around",
            display: "flex",
            margin:"0 10px",
            img: {
                height: 11,
                width: 11,
            }

        }

    }
}))
export const GetPage = styled(Box)(({ theme }) => ({
    display: "flex",
    padding: "18px",
    justifyContent: "space-between",
    alignItems: "end",
    borderBottom: "1px solid",
    ".left": {
        display: "flex",
        ".img": {
            height: 107,
            width: 107,
            overflow:"hidden",
            borderRadius:20,

        }
    },

    ".get": {
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "column",
        marginLeft: 15,
        p: {
            fontWeight: 700,
            fontSize: 19,
        },
        span: {
            fontWeight: 300,
            fontSize: 14,
        },
        ".btn": {
            width: 62,
            lineHeight: "29px",
            background: "#ff9e29",
            borderRadius: 15,
            textAlign: "center",
            fontSize: 15,
            fontWeight: 700
        },
        ".buy": {
            alignItems: "center",
            display: "flex",
            span: {
                marginLeft: 5,
                fontSize: 7,
            }
        }
    },
    ".right": {
        ".img": {
            height: 17,
            width: 17
        }
    }
}))
export const Page = styled(Box)(({ theme }) => ({
    paddingBottom: 20,
    ".container": {
        // overflowY: "hidden",
        overflowX: "auto",
        scrollbarWidth: "none", /* 对 Firefox 隐藏滚动条 */
        scrollbarHeight: "none", /* 对 Firefox 隐藏滚动条 */
        ".containerList": {

            display: "flex",
            justifyContent: "space-between",
            width: "400%",

            ">div": {
                width: 219,
                height: 458
            }
        }
    },
    ".container::-webkit-scrollbar": {
        height: 0,
    }

}))