import React, { useEffect, useState } from "react"
import Output from "../Output"
import LP from "../LP"
import { Search } from "../Header/style"
import link from "../Output/test"
import web3 from "web3"
import { message } from "antd"
function App() {
    const [addr, setAddr] = useState("")
    const [Time, setTime] = useState("")
    const [U, setU] = useState("")
    const [BBC, setBBC] = useState("")
    const handleChildData = (childData) => {
        console.log("从子组件接收到的数据:", childData);
        // 在这里可以处理从子组件传递过来的数据
        // setData(childData)
    };
    const OutPutContact = link();

    const getDetail = async (arr) => {
        if (web3.utils.isAddress(arr)) {
            if (arr.length>0) {
                console.log(arr, 'arr')
                await OutPutContact.methods
                    .getDetail([arr])
                    .call()
                    .then((res) => {
                        setU(res[1])
                        setBBC(res[2])
                        setTime(res[3])
                    });
            }
    
            console.log("Valid address");
        } else {
            message.error("请输入有效地址")
            console.log("Invalid address");
        }
        
    };
    useEffect(() => {
        getDetail(addr)
    }, [addr])
    return (
        <>
            <Search>
                <input type="text" placeholder="请在此输入用户地址" value={addr} onChange={(e) => setAddr(e.target.value)} />
            </Search>
            <Output addr={addr} onDataPass={handleChildData}></Output>
            <LP data={[U, BBC, Time]}></LP>
        </>
    )
}
export default App