import { Box, styled } from "@mui/material";
export const IntroductionPage = styled(Box)(({ theme }) => ({
    background: "#FFF",
    padding: 15,
    ">p": {
        fontSize: 18,
        fontWeight: 600,
        lineHeight: "40px"
    },
    hr: {
        background: "#999999"
    }

}))
export const Chapter = styled(Box)(({ theme }) => ({
    background: "#FFF",
    fontSize: 14,
    padding: "15px 0",
    ".toc ": {
        // listStyle: "none",
        padding: "10px 0",
        margin: 0,
    },

    ".toc li": {
        display: "flex",
        justifyContent: "space-between",
        lineHeight: "30px",
        paddingLeft: 20
    },
    ".toc li:nth-of-type(1)": {
        padding: 0
    },

    ".toc li span": {
        whiteSpace: "nowrap",
    },
    ".aa": {
        flex: 1,
        overflow: "hidden",
        margin: "0 10px"
    },
    ".toc li .aa:: before": {
        content: "'. . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . .  . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . .  . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . .'",
        flexGrow: 1,
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        padding: " 0 10px",
        textAlign: "left"
    }

}))


export const Overview = styled(Box)(({ theme }) => ({
    background: "#FFF",
    marginTop: 12,
    fontSize: 14,
    lineHeight: "26px",
    " ul li": {
        listStyle: "disc !important",
        marginLeft: 30,

    },
    padding: 15,
    ">p": {
        fontSize: 18,
        fontWeight: 600,
        lineHeight: "40px"
    },
    hr: {
        background: "#999999"
    },
    ".title": {
        color: "#585858",
        fontWeight: 600,
        marginTop: 12,
        fontSize: 14
    },
    ".content": {
        margin: "10px 0",
        fontSize: 14
    }

}))