import React from "react"
import { DownPage, GetPage, Page } from "./style"

function App() {
    return (
        <>
            <DownPage>
                <GetPage>
                    <div className="left">
                        <div className="img">
                            <img src="/images/logo.webp" alt="" />
                        </div>
                        <div className="get">
                            <div>
                                <p>ANTWeb3</p>
                                <span>区块链Web3</span>
                            </div>
                            <div className="buy">
                                <div className="btn">获取</div>
                                <span>App 内购买</span>
                            </div>

                        </div>
                    </div>
                    <div className="right">
                        <div className="img">
                            <img src="/images/share.webp" alt="" />
                        </div>
                    </div>

                </GetPage>
                <ul>
                    <li>
                        <p>1个评分</p>
                        <div>5</div>
                        <div className="star">
                            <img src="/images/star.webp" alt="" />
                            <img src="/images/star.webp" alt="" />
                            <img src="/images/star.webp" alt="" />
                            <img src="/images/star.webp" alt="" />
                            <img src="/images/star.webp" alt="" />
                        </div>
                    </li>
                    <li>
                        <p>年龄</p>
                        <div>0</div>
                        <div>岁</div>
                    </li>
                    <li>
                        <p>排行榜</p>
                        <div>#4</div>
                        <div>区块链</div>
                    </li>
                    <li>
                        <p>开发者</p>
                        <div className="person">
                            <img src="/images/person.webp" alt="" />
                        </div>
                        <div>Nakamoto</div>
                    </li>
                </ul>
                <Page>
                   <div className="container">
                    <div className="containerList">
                        <div>
                            <img src="/images/func6.webp" alt="" />
                        </div>
                        <div>
                            <img src="/images/func1.webp" alt="" />
                        </div>
                        <div>
                            <img src="/images/func2.webp" alt="" />
                        </div>
                        <div>
                            <img src="/images/func3.webp" alt="" />
                        </div>
                        <div>
                            <img src="/images/func4.webp" alt="" />
                        </div>
                        <div>
                            <img src="/images/func5.webp" alt="" />
                        </div>
                    </div>
                   </div>
                </Page>
            </DownPage>
        </>
    )
}
export default App