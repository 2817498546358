import { Box, styled } from "@mui/material";
export const Title = styled(Box)(({ theme }) => ({
    fontWeight: "bold",
    fontSize:14,
    lineHeight:"46px",
    paddingLeft:15
}))

export const MechanismPage = styled(Box)(({ theme }) => ({
    padding:"30px 15px",
    background:"#FFF",
    lineHeight:"26px",
    div:{
        marginBottom:30,
    },
    "div:last-of-type":{
        marginBottom:0
    }
}))

export const Care = styled(Box)(({ theme }) => ({
    lineHeight:"26px",
    margin:"23px 15px"
}))