import React from "react"
import { LPage } from "./style"
function convertTimestampToDays(timestamp) {
    // const millisecondsInADay = 86400000; // 一天的毫秒数
    // const days = Math.floor(timestamp / millisecondsInADay);
    var today = new Date(timestamp * 1000);
    //日期
    const DD = String(today.getDate()).padStart(2, '0'); // 获取日
    const MM = String(today.getMonth() + 1).padStart(2, '0'); //获取月份，1 月为 0
    const yyyy = today.getFullYear(); // 获取年
    // 时间
    const hh = String(today.getHours()).padStart(2, '0');       //获取当前小时数(0-23)
    const mm = String(today.getMinutes()).padStart(2, '0');     //获取当前分钟数(0-59)
    const ss = String(today.getSeconds()).padStart(2, '0');     //获取当前秒数(0-59)
    const days = yyyy + '-' + MM + '-' + DD + ' ' + hh + ':' + mm + ':' + ss;
    console.log(days)
    return days;
}
function App({ data }) {
    console.log(data, 'data')
    console.log(Number(data[0]), 'data[0]')
    console.log(Number(data[1]), 'data[1]')
    console.log(convertTimestampToDays(Number(data[2])), 'data[2]')
    return (
        <>
            <LPage>
                <p>LP价值：{
                    data[0]
                        ?
                        (Number(data[0]) / Number(Math.pow(10, 18))).toFixed(2) : 0}

                    U  /



                    {data[1] ? (Number(data[1]) / Number(Math.pow(10, 18))).toFixed(2)

                        : 0} BBC
                </p>
                <p>流动性解锁时间：{data[2] ? convertTimestampToDays(Number(data[2])) : 0}</p>
            </LPage>

        </>
    )
}

export default App