import React from "react"
import { FooterPage } from "./style"

function App() {
    return (
        <>
           
           <FooterPage>
            <span>联系我们</span>
            <div>
                <div className="img">
                    <img src="/images/twitter.webp" alt="" />
                </div>
                <div className="img">
                    <img src="/images/telegram.webp" alt="" />
                </div>
            </div>
           </FooterPage>
        </>
    )
}

export default App